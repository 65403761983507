<template>
    <!-- Backdrop -->
    <div v-show="showModal == true" @click="closeModal" class="fixed z-10 inset-0 bg-gray-800 bg-opacity-50"></div>

    <!-- Modal -->
    <div v-show="showModal == true" class="fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg rounded-lg p-8">
        <!-- Modal content -->
        <h1 class="text-lg font-bold mb-4 uppercase">Add Inventory Part</h1>
        <!-- <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"> -->
            <div class="flex-auto py-10 pt-0">
                <hr class="mt-3 border-b-1 border-blueGray-300">
                <form @submit.prevent="submitForm">
                    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    Part Information
                    </h6>
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                    Part Number
                                </label>
                                <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model="itemPost.part_number" >
                            </div>
                        </div>
                        <div class="w-full lg:w-7/12 px-4">
                            <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                Description
                            </label>
                            <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model="itemPost.designation" >
                            </div>
                        </div>
                        <div class="w-full lg:w-2/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                    Quantity
                                </label>
                                <input type="number" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model="itemPost.quantity">
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                    Aircraft Type
                                </label>
                                <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model="itemPost.aircraft_type">
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                    Condition
                                </label>
                                <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model="itemPost.condition">
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                    Certifications
                                </label>
                                <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model="itemPost.certification">
                            </div>
                        </div>
                    </div>
                    <!-- <hr class="mt-6 border-b-1 border-blueGray-300"> -->
                    <div class="flex justify-end">
                        <button class="mt-[20px] md:text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-200 font-semibold font-body text-center justify-center border-0 border-transparent placeholder-white focus-visible:outline-none focus:outline-none  bg-black text-white px-5 md:px-6 lg:px-8 py-2 md:py-3.5 lg:py-4 hover:text-white hover:bg-gray-600 hover:shadow-cart h-10 lg:h-10 mt-1 text-sm lg:text-base w-full sm:w-auto" type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        <!-- </div> -->

        <!-- Close button -->
        <button @click="closeModal" class="absolute top-0 right-0 m-4">
            <svg class="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </button>
    </div>
</template>


<script setup>
import { ref, watch } from 'vue';
import { useStore } from '@/stores/store'
import { InventoryRemoteDataSource } from '@/module/inventory/api/InventoryRemoteDataSource'

const store = useStore();
const params = defineProps(['isAdding']);
const emit = defineEmits(['closeModal', 'stateSuccess', 'stateError']);
const showModal = ref(false);
const itemPost = ref({
    part_number: '',
    designation: '',
    quantity: 1, 
    aircraft_type: '',
    condition: 'FN',
    certification: 'COC/FORM1',
})

watch(() => params.isAdding, (newValue, oldValue) => {
    showModal.value = newValue;
});

function closeModal() {
    showModal.value = false;
    emit('closeModal')
}

const submitForm = async() => {
    try {
        await InventoryRemoteDataSource.postInventoryItem(itemPost.value)
        emit('stateSuccess', itemPost)
    } catch (error) {
        emit('stateError', error)
    }
}

</script>


<template>
    <!-- AUTH0 REACTIVATE  -->
        <!-- If is Authenticated get the structure layout -->
        <!-- <div v-if="isAuthenticated"> -->
        <div>
          <div  class="w-screen h-screen flex"> 
            <SideBar  v-show=showSideBar />
            <div class="w-full h-full bg-gray-400">
              <TopBar @toggleSideBar="toggleSideBar()"/>
                <SuccessAlertAbsolute v-if="stateStore.isSuccess == true" :message="stateStore.successMessage" />
                <ErrorAlertAbsolute v-if="stateStore.isError == true" :message="stateStore.errorMessage" />              <slot />
            </div>
          </div>
        </div>
        
        <!-- Else get the simple page  -->
        <!-- <div v-else>
          <router-view />
        </div> -->
        
    </template>
    
<script setup>
import { ref } from 'vue'
import SideBar from '@/components/navigation/SideBar.vue';
import TopBar from '@/components/navigation/TopBar.vue';
import SuccessAlertAbsolute from '@/components/states/success/SuccessAlertAbsolute.vue'
import ErrorAlertAbsolute from '@/components/states/error/ErrorAlertAbsolute.vue'
import { useStateStore } from '@/stores/StateStore'

const showSideBar = ref(true)
const stateStore = useStateStore()

const toggleSideBar = () => { 
    showSideBar.value=!showSideBar.value
}


</script>
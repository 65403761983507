import * as XLSX from 'xlsx';

class ExcelService {

    constructor(expectedHeaders) {
        this.expectedHeaders = expectedHeaders;
    }
    
    async parseXlsx(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
                if (jsonData.length === 0 || !this.validateHeaders(jsonData[0])) {
                    reject(new Error('Invalid file headers. Please ensure the headers are correct.'))
                }
                resolve(jsonData)
            };
            reader.onerror = (error) => {
                reject(new Error(`Error reading XLSX file: ${error.message}`));
            };
            reader.readAsArrayBuffer(file);
        })
    }
    
    validateHeaders(headers) {
        if (headers.length !== this.expectedHeaders.length) return false;
        return headers.every((header, index) => header.trim().toLowerCase() === this.expectedHeaders[index]);
    }


    static downloadExcelFile(filePath, fileName) {
        const link = document.createElement('a');
        link.href = filePath;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}


export { ExcelService }
// import { auth0 } from '@/auth0';

class InventoryService {

    static convertArrayTableIntoArrayOfJSON(dataArray) {

        // Step 1: Extract header
        const [header, ...rows] = dataArray;

        // Step 2: Convert rows to objects
        const dataObjects = rows.map(row => {
        return header.reduce((acc, key, index) => {
            acc[key] = row[index] !== undefined ? String(row[index]) : null;
            return acc;
        }, {});
        });
        return dataObjects
    }

    static createRegExHyphenSlash = (search) => {
        if (search !== "") {
            const nakedWord = search.replace(/[-\/]/g, '');
            const fullHyphenatedWord = nakedWord.replace(/[A-Za-z0-9]/g, (match) => match + '-');
            const hyphenatedWord = fullHyphenatedWord.slice(0, -1);
            const searchTermWithHyphenOrSlash = hyphenatedWord.replace(/[-]/g, '[-\/]*');
            const regEx = new RegExp(searchTermWithHyphenOrSlash, 'i')
            return regEx
        }else{
            return ""
        }
    }

}

export { InventoryService }
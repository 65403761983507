const domain = process.env.VUE_APP_AUTH0_DOMAIN ||'dev-przjxt651znl02h7.eu.auth0.com'
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID ||'8hJZbWNkAMXWzUVQAOjV3YKCA0hEELtk'
const audience = process.env.VUE_APP_AUTH0_AUDIENCE || 'https://msmaviation.com/backend' //'http://localhost:3001';
const redirectUri = process.env.VUE_AUTH0_CALLBACK_URL || `${window.location.origin}/callback`;
const logoutUri = `${window.location.origin}/`;

export default {
    domain,
    clientId,
    audience,
    redirectUri,
    logoutUri
};

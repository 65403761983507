<template>
    <!-- Backdrop -->
    <div v-show="showModal == true" @click="closeModal" class="fixed z-10 inset-0 bg-gray-800 bg-opacity-50"></div>

    <!-- Modal -->
    <div v-show="showModal == true" class="fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg rounded-lg p-8">
        <!-- Modal content -->
        <h1 class="text-lg font-bold mb-4 uppercase">Delete Part</h1>

        <p class="uppercase normal-case text-[1rem]">Are you sure you want to remove <span class="uppercase">{{ itemDelete.designation ?? '' }}</span> ?</p>

        <!-- <hr class="mt-6 border-b-1 border-blueGray-300"> -->
        <div class="flex justify-end mt-2">
            <button class="mt-[20px] md:text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-200 font-semibold font-body text-center justify-center border-0 border-transparent placeholder-white focus-visible:outline-none focus:outline-none  bg-black text-white px-5 md:px-6 lg:px-8 py-2 md:py-3.5 lg:py-4 hover:text-white hover:bg-gray-600 hover:shadow-cart h-10 lg:h-10 mt-1 text-sm lg:text-base w-full sm:w-auto" 
                    type="submit"
                    @click="deleteItem(itemDelete.id)"
                    >
                Confirm
            </button>
        </div>

        <!-- Close button -->
        <button @click="closeModal" class="absolute top-0 right-0 m-4">
            <svg class="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </button>
    </div>
</template>


<script setup>
import { ref, watch } from 'vue';
import { useStore } from '@/stores/store'
import { InventoryRemoteDataSource } from '@/module/inventory/api/InventoryRemoteDataSource'

const store = useStore();
const params = defineProps(['isDelete', 'item']);
const emit = defineEmits(['closeModal','stateError', 'stateSuccess']);
const showModal = ref(false);
const itemDelete = ref({
    id: '',
})

watch(() => params.isDelete, (newValue, oldValue) => {
    showModal.value = newValue;
});

watch(() => params.item, (newValue, oldValue) => {
    itemDelete.value = {...newValue};
});

function closeModal() {
    showModal.value = false;
    emit('closeModal')
}

async function deleteItem(idItem) {
    try {
        await InventoryRemoteDataSource.deleteInventoryItem(idItem)
        emit('stateSuccess')
    } catch (error) {
        emit('stateError')
    }
}

</script>


<template>
    <!-- Backdrop -->
    <div v-show="showModal == true" @click="closeModal" class="fixed z-10 inset-0 bg-gray-800 bg-opacity-50"></div>

    <!-- Modal -->
    <div v-show="showModal == true" class="fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg rounded-lg p-10">
        <!-- Modal content -->
        <h1 class="text-lg font-bold mb-4 uppercase">Upload Excel file</h1>
            <div class="flex-auto pt-0">
                <hr class="mt-3 border-b-1 border-blueGray-300">
                <div class="mb-4 text-center">
                    <p class="my-5">Please upload your Excel file or download the template:</p>
                    <div class="flex flex-row justify-between items-center w-full"> <!-- Center align the flex items -->
                    <!-- File Input with Styled Browse Button -->
                    <label class="relative inline-flex items-center">
                        <!-- Hidden File Input -->
                        <input 
                        type="file" 
                        ref="fileInput" 
                        accept=".xlsx" 
                        @change="handleFileUpload($event)"
                        class="absolute inset-0 opacity-0 cursor-pointer"
                        />
                        <!-- Browse Button -->
                        <span class="inline-flex items-center text-sm leading-4 cursor-pointer transition ease-in-out duration-200 font-normal text-center justify-center border border-transparent text-white bg-black px-5 py-2 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-700 rounded">
                        Browse
                        </span>
                        <!-- Display File Name -->
                        <span id="file-name" class="ml-2 text-gray-700 text-sm">
                            {{ fileName || 'No file chosen' }}
                        </span>
                    </label>
                    <button 
                        @click="downloadTemplate" 
                        class="text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-200 font-normal text-center justify-center border border-gray-300 text-gray-700 bg-gray-200 px-3 py-1.5 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded">
                        <i class="fa-solid fa-download"></i>
                    </button>
                    </div>
                </div>
            </div>
        <!-- </div> -->

        <!-- Close button -->
        <button @click="closeModal" class="absolute top-0 right-0 m-4">
            <svg class="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </button>
    </div>
</template>


<script setup>
import { ref, watch } from 'vue';
import { useStore } from '@/stores/store'
import { ExcelService } from '@/services/ExcelService'
import { InventoryService } from '@/module/inventory/service/InventoryService'
import { InventoryRemoteDataSource } from '@/module/inventory/api/InventoryRemoteDataSource'

const store = useStore();
const params = defineProps(['isModalOpen']);
const emit = defineEmits(['closeModal', 'stateSuccess', 'stateError']);
const showModal = ref(false);
const fileName = ref('')
const expectedHeaders = [
    'part_number',
    'designation',
    'quantity',
    'aircraft_type',
    'condition',
    'certification'
];
const excelService = new ExcelService(expectedHeaders);


watch(() => params.isModalOpen, (newValue, oldValue) => {
    showModal.value = newValue;
});

function closeModal() {
    showModal.value = false;
    emit('closeModal')
}

const downloadTemplate = () => {
    ExcelService.downloadExcelFile('/assets/import_catalogue_parts_template.xlsx', 'template_import_part.xlsx')
}

const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file.length > 0) {
        fileName.value = fileInput.files[0].name;
    } else {
        fileName.value = ''; // Reset if no file is chosen
    }
    if (file) {
        try {
            const dataArray = await excelService.parseXlsx(file);
            const dataObjects = InventoryService.convertArrayTableIntoArrayOfJSON(dataArray)
            InventoryRemoteDataSource.postInventoryList(dataObjects)
            emit('stateSuccess')
        } catch (error) {
            emit('stateError', error)     
        }
    }
};

</script>


import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useStateStore = defineStore('stateStore', () => {

    const timeoutAlertMessage = 4000
    const isSuccess = ref(false)
    const successMessage = ref('')
    const isError = ref(false)
    const errorMessage = ref('this is an error')

    function displaySuccessAlertMessage(message) {
        successMessage.value = message
        isSuccess.value= true
        setTimeout(() => {
            isSuccess.value=false
        }, timeoutAlertMessage);
    }

    function displayErrorAlertMessage(message) {
        errorMessage.value = message
        isError.value= true
        setTimeout(() => {
            isError.value=false
        }, timeoutAlertMessage);
    }



    return { 
        isSuccess,
        successMessage,
        isError,
        errorMessage,
        displaySuccessAlertMessage,
        displayErrorAlertMessage
    }
})

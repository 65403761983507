import { defineStore } from 'pinia'
import axios from 'axios';
import { ref } from 'vue'
import { CRUDapi } from '@/api/CRUDapi.js'
import { useGlobalStore } from "@/stores/GlobalStore";
import { InventoryRemoteDataSource } from '@/module/inventory/api/InventoryRemoteDataSource';

export const useStore = defineStore('store', () => {

let apiURL
let baseURL
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
    apiURL = 'http://localhost:3001/api'
    baseURL = 'http://localhost:3000'
}else{
    apiURL = ''
    baseURL = ''
}

let data = {};


async function loadDataAll() {
    const apiCall = new Promise((resolve, reject) => {
        console.log('starting Load data...')
        this.fetchDataAPI('inventory')
            .then(result => {
                this.inventoryData = result
                resolve('Data loaded sucessfully in the frontend')
            })
            .catch(error => { reject(error)})
    })
    const timeoutPromise = this.timeoutPromise(8000)
    return Promise.race([apiCall, timeoutPromise])
}

async function loadData(tableName) {
    const apiCall = new Promise((resolve, reject) => {
        console.log('starting Load data...')
        this.fetchDataAPI(tableName)
            .then(result => {
                this.data[tableName] = result
                console.log(data)
                resolve('Data loaded sucessfully')
            })
            .catch(error => { reject(error)})
    })
    const timeoutPromise = this.timeoutPromise(4000)
    return Promise.race([apiCall, timeoutPromise])
}



async function fetchDataAPI(DBName){
    try {
        const response = await axios.get(`${apiURL}/${DBName}`);
        return response.data
    } catch (error) {
        throw error;
    }
}

// async function getUserProfile(){
//     try {
//         const response = await axios.get(`${baseURL}/profile`)
//         return response.data.user
//     }catch (error) {
//         throw error
//     }
// }









function timeoutPromise(miliseconds) {
    return new Promise((resolve, reject) => {
        setTimeout(()=> {
        reject(new Error("Timeout" + miliseconds/1000 + " seconds exceeded"))
        }, miliseconds)
    })
}

return { 

    data,
    loadDataAll,
    loadData,
    fetchDataAPI,
    // getUserProfile,
    timeoutPromise 
}
})


import axios from 'axios';
import { auth0 } from '@/auth0';


// Create an instance of Axios with custom configuration
const api = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_API_URL || 'http://localhost:3001/api', // Your API base URL
    timeout: 10000, // expression en milisecondes, 10s pour envoyer une erreur après un temps de latence 
    headers: {
        'Content-Type': 'application/json',
    }
});

// Optionally, you can add request interceptors
api.interceptors.request.use(
    async(config) => {

    // Add username for auditing purposes
    if (config.method === 'post' || config.method === 'put') {
        // Add user field to the request data
        if (Array.isArray(config.data)) {
            config.data = config.data.map(item => ({
                ...item,
                user_name: sessionStorage.userName,
            }));
        } else {
            config.data = {
                ...config.data,
                user_name: sessionStorage.userName,
            };
        }
    }

    // Add token in the Header
    const token = await auth0.getAccessTokenSilently()
    config.headers.Authorization = `Bearer ${token}`;
    
    return config;
    },
    (error) => {
    // Handle request error
    return Promise.reject(error);
    }
);

// Optionally, you can add response interceptors
api.interceptors.response.use(
    (response) => {
    // Modify response data before passing it to the caller
    return response;
    },
    (error) => {
    // Handle response error
    return Promise.reject(error);
    }
);

export default api;
import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from "@auth0/auth0-vue";
import HomeView from '../views/HomeView.vue'
import InventoryView from '@/views/InventoryView.vue'
import SignInPage from '@/views/SignInPage.vue'
import CallBackPage from '@/auth0/pages/CallBackPage.vue'
import ProfilePage from '@/views/ProfilePage.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    beforeEnter: authGuard
  },
  {
    path: '/',
    name: 'signIn',
    component: SignInPage,
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallBackPage,
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: InventoryView,
    beforeEnter: authGuard
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
    beforeEnter: authGuard
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFoundPage,
  },    



  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

<template>
    <div class="absolute top-14 right-4 z-50 mx-auto transform translate-x-0">
        <div class="relative px-6 pr-12 py-3 my-2 rounded-lg border text-alertGreenText bg-alertGreenBg border-alertGreenBorder" role="alert">
            <strong class="font-bold"> Success </strong>
            <span class="block sm:inline">{{ params.message }}</span>
            <!-- Close button -->
            <span class="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center cursor-pointer" @click="dismissAlert">
                <svg class="fill-current h-6 w-6 text-alertGreenText" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>
        </div>
    </div>
</template>

<script setup>

import { defineProps } from 'vue'
import { useStateStore } from '@/stores/StateStore.js'

const params = defineProps(['message'])
const stateStore = useStateStore()

const dismissAlert = () => {
    stateStore.isSuccess = false
}


</script>
<template>
    <tr v-for="item in props.items" class="hover:bg-gray-100">                    
        <td class="px-3">{{ item.part_number }}</td>
        <td class="px-3">{{ item.designation }}</td>
        <td class="px-3">{{ item.quantity }}</td>
        <td class="px-3">{{ item.aircraft_type }}</td>
        <td class="px-3">{{ item.condition }}</td>
        <td class="px-3">{{ item.certification }}</td>
        <DropDownActionComponent :item="item" @stateError="stateError" @successDelete="successDelete" @successUpdate="successUpdate"/>
    </tr>
</template>


<script setup>
import DropDownActionComponent from '@/components/table/DropdownActionComponent.vue'

const props = defineProps(['items'])
const emits = defineEmits(['stateError', 'successDelete', 'successUpdate'])

const stateError = () => {
    emits('stateError')
}

const successDelete = () => {
    emits('successDelete')
}

const successUpdate = () => {
    emits('successUpdate')
}

</script>
<template>
    <PageLayout>
    <div class="h-[calc(100%-50px)] bg-white flex p-[20px] flex flex-col">
        <!-- Display Alerts -->
        <!-- <SuccessAlert  v-if="isSuccess" :message="successMessage" @closeAlert="toggleSuccessAlert()"/>
        <ErrorAlert v-if="isError" :message="errorMessage" @closeAlert="toggleErrorAlert()"/> -->


        <h2 class="font-bold text-xl pb-[5px]">Inventory</h2>
        <div class="flex flex-row justify-between align-items-center" >
            <div class="w-[75%] flex flex-col">
                <p class="mb-2">Modify the parts of the inventory here</p>
                <div class="w-full max-w-md">
                    <!-- Search bar -->
                    <div class="relative">
                        <input type="text" placeholder="Search..." v-model="searchInput" @keyup.enter="searchPart()" class="w-full pl-4 pr-12 py-2 text-gray-700 bg-white border border-gray-300 rounded-full focus:ring-2 focus:ring-gray-500 focus:outline-none focus:border-gray-500">
                        <button @click="searchPart()"  class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-900 text-white rounded-full h-10 w-10 flex items-center justify-center hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex flex-col justify-end w-auto">
                <button
                    class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none flex items-center gap-3"
                    type="button"
                    @click="toggleAddModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 6v12m-6-6h12"></path>
                    </svg>
                    Add new part
                </button>
                <div class="flex flex-row items-end align-items-center m-2 cursor-pointer " type="button" @click="toggleUploadExcelFileModal()">
                    <div class='text-gray-500 px-5 hover:text-black'>
                        <i class="fa-solid fa-upload"></i>
                        Upload Excel
                    </div>
                </div>
            </div>
        </div>
        
        <div class="flex flex-col justify-between h-[80%] w-[100%] overflow-y-auto px-[10px] py-[20px]">
            <ErrorMessage v-if="isErrorLoading" :errorMessage="errorMessage" />
            <Spinner v-if="isLoading && !isErrorLoading" />
            <table v-if="!isLoading && !isErrorLoading" class="table-auto w-full divide-y">
                <thead>
                    <tr>
                        <th class="px-3 py-3 bg-gray-50 text-left text-xs leading-3 font-bold text-gray-700 uppercase tracking-wider">Part Number</th>
                        <th class="px-3 py-3 bg-gray-50 text-left text-xs leading-3 font-bold text-gray-700 uppercase tracking-wider">Designation</th>
                        <th class="px-3 py-3 bg-gray-50 text-left text-xs leading-3 font-bold text-gray-700 uppercase tracking-wider">Quantity</th>
                        <th class="px-3 py-3 bg-gray-50 text-left text-xs leading-3 font-bold text-gray-700 uppercase tracking-wider">Aircraft Type</th>
                        <th class="px-3 py-3 bg-gray-50 text-left text-xs leading-3 font-bold text-gray-700 uppercase tracking-wider">Condition</th>
                        <th class="px-3 py-3 bg-gray-50 text-left text-xs leading-3 font-bold text-gray-700 uppercase tracking-wider">Certification</th>
                        <th class="px-3 py-3 bg-gray-50 text-center text-xs leading-3 font-bold text-gray-700 uppercase tracking-wider">Action</th>

                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 text-xs uppercase overflow-y-auto">
                    <TableItems v-if="isSearch && dataFiltered.length > 0" :items="dataFiltered" @stateError="displayError" @successDelete="displaySuccessDelete" @successUpdate="displaySuccessUpdate"/>                    
                    <TableItems v-if="!isSearch && storeI.inventoryDataPaginated.length >0" :items="storeI.inventoryDataPaginated" @stateError="displayError" @successDelete="displaySuccessDelete" @successUpdate="displaySuccessUpdate"/>
                </tbody>            
            </table>
            <p v-if="(isSearch && dataFiltered?.length === 0) || (!isSearch && storeI.inventoryDataPaginated?.length === 0)" class="capitalize text-center text-sm">No items available...</p>
            <!-- Pagination Component -->
            <Pagination class="w-full justify-center"
                :totalPages=storeI.totalPages
                :currentPage=currentPage
                @setCurrentPage="setCurrentPage"
            />
        </div>
    </div>
    <AddInventoryModal :isAdding=isAddModal @closeModal="toggleAddModal" @stateSuccess="displaySuccessAdd" @stateError="displayError"/>

    <UploadExcelFileModal :isModalOpen="isUploadExcelFileModal" @closeModal="toggleUploadExcelFileModal" @stateSuccess="displaySuccessImport" @stateError="displayError"/>
    </PageLayout>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from '@/stores/store'
import PageLayout from '@/components/layout/PageLayout.vue';
import DropDownActionComponent from '@/components/table/DropdownActionComponent.vue'
import TableItems from '@/module/inventory/components/TableItems.vue'
import AddInventoryModal from '@/components/modal/AddInventoryModal.vue'
import UploadExcelFileModal from '@/components/modal/UploadExcelFileModal.vue'
import Pagination from '@/components/navigation/Pagination.vue'
import ErrorMessage from '@/components/states/error/ErrorMessage.vue';
import Spinner from '@/components/states/loading/Spinner.vue'
import { useStateStore } from '@/stores/StateStore'
import { InventoryService } from '@/module/inventory/service/InventoryService'
import { useInventoryStore } from '@/module/inventory/store/InventoryStore'

const storeI = useInventoryStore()
const storeS = useStateStore()
const isEditModal = ref(false)
const itemModal = ref({})
const isLoading = ref(true)
const isErrorLoading = ref(false)
const isAddModal = ref(false)
const isUploadExcelFileModal = ref(false)
const errorMessage = ref('Ooops something went wrong')
const currentPage = ref(1)
const pageSize=ref(100)
const searchInput=ref('')
const isSearch=ref(false)
const dataFiltered=ref(null)

onMounted(async () => {
    try {
        await storeI.getInventoryPaginated(currentPage.value, pageSize.value)
        storeI.getInventoryAll()
        isLoading.value=false
    } catch (error) {
        isErrorLoading.value=true
        errorMessage.value = error.message
    }
})


const toggleEditModal = (item) => {
    itemModal.value=item
    isEditModal.value=!isEditModal.value
}

const toggleAddModal = () => {
    isAddModal.value = !isAddModal.value
}

const toggleUploadExcelFileModal = () => {
    isUploadExcelFileModal.value = !isUploadExcelFileModal.value
}


const searchPart = () => {    
    if (searchInput.value =='') {
        isSearch.value=false
        return
    }
    isSearch.value=true
    isLoading.value=true
    const regEx = InventoryService.createRegExHyphenSlash(searchInput.value)
    dataFiltered.value = storeI.inventoryData.filter(data => regEx.test(data.part_number) || regEx.test(data.designation))
    isLoading.value=false
}

// const dataFiltered = computed(()=> {
//     if (searchInput.value == ''){
//         return null
//     } else {
//         const regEx = InventoryService.createRegExHyphenSlash(searchInput.value)
//         console.log('storeI.inventoryData', storeI.inventoryData)
//         return storeI.inventoryData.filter(data => regEx.test(data.part_number) || regEx.test(data.designation))
//     }
// })

const setCurrentPage = async (page) => {
    currentPage.value=page
    await storeI.getInventoryPaginated(currentPage.value, pageSize.value)
}

const displaySuccessDelete = async (item) => {
    let message
    if(item == null){
        message = `${itemModal.value.designation} has been removed`
    } else {
        message = `${item.designation} has been removed`
    }
    storeS.displaySuccessAlertMessage(message)
    await storeI.getInventoryAll()
}

const displaySuccessUpdate = async (item) => {
    storeS.displaySuccessAlertMessage(`${item.designation} has been updated`)
    await storeI.getInventoryAll()
}

const displaySuccessAdd = async (item) => {
    isAddModal.value=false
    storeS.displaySuccessAlertMessage(`${item.value.designation} has been added`)
    await storeI.getInventoryAll()
}

const displaySuccessImport = async () => {
    isUploadExcelFileModal.value = false
    storeS.displaySuccessAlertMessage('File has been uploaded')
    try {
        setTimeout(async() => {
            await storeI.getInventoryAll()
        }, 1000);
    }catch(error){
        storeS.displayErrorAlertMessage(error)
    }
}

const displayError = async (error) => {
    isAddModal.value = false
    isUploadExcelFileModal.value=false
    let message
    if (!error || error.message == null) { 
        message ='Bad request' 
    } else { 
        message = error.message
    }
    storeS.displayErrorAlertMessage(message)
}




</script>
<template>
    <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <h1 class="text-2xl mb-5">404 -Not Found</h1>
        <p>It seems like you are trying to access a ressource that is not available</p>
        <p>Let's get you back on track</p>
        <button @click="goBack" class="bg-gray-800 hover:bg-gray-600 text-white text-xs px-4 pt-1 pb-2 m-6 rounded-lg focus:outline-none focus:ring focus:ring-blue-300">
            Go Back
        </button>
    </div>
</template>


<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { loginWithRedirect } = useAuth0();

const goBack = () => {
        loginWithRedirect();
}

</script>

<template>
    <div v-if="error" class="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <h1 class="text-2xl mb-5">403 - Accès Interdit</h1>
        <p>Aïe, on dirait bien que tu n'ais pas accès à la ressource</p>
        <p>Contacte nos équipes pour recevoir de l'aide </p>
        <button @click="logoutAuth0" class="bg-gray-800 hover:bg-gray-600 text-white text-xs px-4 pt-1 pb-2 m-6 rounded-lg focus:outline-none focus:ring focus:ring-blue-300">
            Log out
        </button>
    </div>

    <LoaderPage v-if="loading"/>

</template>


<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { onMounted, ref } from "vue";
import { useRouter } from 'vue-router'
import LoaderPage from "@/views/LoaderPage.vue";

const router = useRouter()
const { error, isAuthenticated, isLoading, logout, user } = useAuth0();
const loading = ref(true)

onMounted(() => {
    setTimeout(() => {
        if(!isLoading.value && isAuthenticated.value){
        loading.value=false
        sessionStorage.setItem('userName', user.value.name);
        router.push('/home')
    }
    }, 1000)
})

const logoutAuth0 = () => {
    logout()
}


</script>

import { defineStore } from 'pinia'
import { ref } from 'vue'
import { CRUDapi } from '@/api/CRUDapi.js'
import { useGlobalStore } from "@/stores/GlobalStore";
import { InventoryRemoteDataSource } from '@/module/inventory/api/InventoryRemoteDataSource';

export const useInventoryStore = defineStore('inventoryStore', () => {

let inventoryData = ref(null);
const inventoryDataPaginated = ref(null)
const totalPages = ref(1)
const globalStore = useGlobalStore()


async function getInventoryAll() {
    try {
        inventoryData.value = await InventoryRemoteDataSource.getInventoryAll()
    } catch (error) {
        globalStore.logError(error, 6)
    }
}

async function getInventoryPaginated(page, pageSize) {
    try {
        const response = await InventoryRemoteDataSource.getInventoryPaginated(page, pageSize)
        inventoryDataPaginated.value = response.items
        totalPages.value = response.totalPages
    } catch (error) {
        globalStore.logError(error, 6)
    }
}

///////////////////////////////////////
// CRUD API SERVICE ON INVENTORYHISTORY
///////////////////////////////////////

async function getInventoryHistoryItems(id) {
    try {
        const response = await CRUDapi('GET', 'inventoryhistory', id)
        return response
    } catch (error) {
        throw(error)
    }
}



return {
    inventoryData,
    inventoryDataPaginated,
    totalPages,    
    getInventoryAll,
    getInventoryPaginated,
    getInventoryHistoryItems,
}

})
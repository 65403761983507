<template>
    <!-- Backdrop -->
    <div v-show="showModal == true" @click="closeModal" class="fixed z-10 inset-0 bg-gray-800 bg-opacity-50"></div>

    <div v-show="showModal == true" class="fixed z-20 w-[70%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg rounded-lg p-8">
    <h1 class="text-lg font-bold mb-4 uppercase">View History Details</h1>
            <table v-if="!isLoading && !isError" class="min-w-full">
                <thead>
                    <tr>
                        <th class="text-left uppercase font-bold text-xs text-blueGray-600">PN</th>
                        <th class="text-left uppercase font-bold text-xs text-blueGray-600">Description</th>
                        <th class="text-left uppercase font-bold text-xs text-blueGray-600">Quantity</th>
                        <th class="text-left uppercase font-bold text-xs text-blueGray-600">Aircraft Type</th>
                        <th class="text-left uppercase font-bold text-xs text-blueGray-600">Condition</th>
                        <th class="text-left uppercase font-bold text-xs text-blueGray-600">Certs</th>
                        <th class="text-left uppercase font-bold text-xs text-blueGray-600">Timestamp</th>
                        <th class="text-left uppercase font-bold text-xs text-blueGray-600">User Name</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 text-xs uppercase overflow-y-auto">
                    <tr>
                        <td class="border-b border-blueGray-300 py-3">{{ params.item.part_number }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ params.item.designation }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ params.item.quantity }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ params.item.aircraft_type }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ params.item.condition }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ params.item.certification }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ formatDate(params.item.updatedAt) }}</td>
                        <td class="border-b border-blueGray-300 py-3 capitalize">{{ params.item.user_name }}</td>
                    </tr>
                    <tr v-for="historyItem in historyItems" :key="historyItem.id">
                        <td class="border-b border-blueGray-300 py-3">{{ historyItem.part_number }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ historyItem.designation }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ historyItem.quantity }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ historyItem.aircraft_type }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ historyItem.condition }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ historyItem.certification }}</td>
                        <td class="border-b border-blueGray-300 py-3">{{ formatDate(historyItem.timestamp) }}</td>
                        <td class="border-b border-blueGray-300 py-3 capitalize">{{ historyItem.user_name }}</td>
                    </tr>
                </tbody>
            </table>
            <Spinner v-if="isLoading" />
            <ErrorMessage v-if="isError" :errorMessage="errorMessage"/>
        <!-- </div> -->

        <!-- Close button -->
        <button @click="closeModal" class="absolute top-0 right-0 m-4">
            <svg class="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </button>
    </div>
</template>


<script setup>
import { ref, watch } from 'vue';
import { useInventoryStore } from '@/module/inventory/store/InventoryStore'
import Spinner from '@/components/states/loading/Spinner.vue'
import ErrorMessage from '@/components/states/error/ErrorMessage.vue'

const storeI = useInventoryStore();
const params = defineProps(['isDetail', 'item']);
const emit = defineEmits(['closeModal']);
const showModal = ref(false);
const historyItems = ref(null)
const isLoading = ref(true)
const isError = ref(false)
const errorMessage =ref('')

watch(() => params.isDetail, (newValue, oldValue) => {
    showModal.value = newValue;
});

watch(showModal, async (newValue, oldValue) => {
    if (newValue === true) {
        // Get the data when showModal becomes true
        console.log(params.item)
        try {
            isLoading.value=true
            historyItems.value = await storeI.getInventoryHistoryItems(params.item.id);
            isLoading.value=false
        } catch (error) {
            isLoading.value=false
            console.log(error)
            errorMessage.value=error.message
            isError.value=true
        }
    }
});


function closeModal() {
    showModal.value = false;
    isError.value=false
    emit('closeModal')
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
}

</script>


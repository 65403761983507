<!-- Pagination.vue -->
<template>
    <div class="flex items-center space-x-2">
        <button
        @click="prevPage"
        :disabled="currentPage === 1"
        class="bg-gray-900 text-white rounded-full h-10 w-10 flex items-center justify-center hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
        <i class="fa fa-chevron-left"></i>
        </button>

        <!-- Conditionally display page numbers -->
        <button
        v-if="showPrevPage"
        @click="goToPage(currentPage - 1)"
        class="h-10 w-10 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-gray-400 bg-white text-gray-900 border border-gray-300 hover:bg-gray-200"
        >
        {{ currentPage - 1 }}
        </button>

        <button
        @click="goToPage(currentPage)"
        class="h-10 w-10 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-gray-400"
        :class="{
            'bg-gray-900 text-white': currentPage === currentPage,
            'bg-white text-gray-900 border border-gray-300 hover:bg-gray-200': currentPage !== currentPage
        }"
        >
        {{ currentPage }}
        </button>

        <button
        v-if="showNextPage"
        @click="goToPage(currentPage + 1)"
        class="h-10 w-10 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-gray-400 bg-white text-gray-900 border border-gray-300 hover:bg-gray-200"
        >
        {{ currentPage + 1 }}
        </button>

        <button
        @click="nextPage"
        :disabled="currentPage === totalPages"
        class="bg-gray-900 text-white rounded-full h-10 w-10 flex items-center justify-center hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
        <i class="fa fa-chevron-right"></i>
        </button>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps(['totalPages', 'currentPage'])
const emits = defineEmits(['setCurrentPage'])

const showPrevPage = computed(() => props.currentPage > 1);
const showNextPage = computed(() => props.currentPage < props.totalPages);

const goToPage = (page) => {
    if (page >= 1 && page <= props.totalPages && page !== props.currentPage) {
        emits('setCurrentPage', page);
    }
};

const prevPage = () => {
    if (props.currentPage > 1) {
        goToPage(props.currentPage - 1);
    }
};

const nextPage = () => {
    if (props.currentPage < props.totalPages) {
        goToPage(props.currentPage + 1);
    }
};

</script>

import api from '@/api/api.js'

async function CRUDapi(verb, endpoint, id, data){
    return new Promise(async (resolve, reject) => {  
        try {
            let response;
            switch (verb) {
                case 'DELETE':
                    response = await api.delete(`/${endpoint}/${id}`)
                    console.log(response)
                    resolve(response)                
                    break;
                case 'PUT':
                    response = await api.put(`/${endpoint}/${id}`, data)
                    resolve(response)                
                    break;
                case 'POST':
                    response = await api.post(`/${endpoint}`, data)
                    resolve(response)                
                    break;
                case 'GET':
                    if (id != null) {
                        response = await api.get(`/${endpoint}/${id}`);
                        resolve(response.data) 
                    } else {
                        response = await api.get(`/${endpoint}`)
                        resolve(response.data)
                    }
                    break;
                default:
                    throw new Error('Bad request')
            }
        } catch (error) {
            reject(error)
        }
    })
}

export { CRUDapi };
import { useGlobalStore } from "@/stores/GlobalStore";
import { useStore } from "@/stores/store";
import { CRUDapi } from "@/api/CRUDapi";

////////////////////////////////
// CRUD API SERVICE ON INVENTORY 
////////////////////////////////
class InventoryRemoteDataSource {

    static async getInventoryAll() {
        return CRUDapi('GET', 'inventory')
    }

    static async getInventoryPaginated(page, pageSize) {
        return CRUDapi('GET', `inventory?page=${page}&pageSize=${pageSize}`)
    }

    static async getInventoryItem(id) {
        return CRUDapi('GET', 'inventory', id)
    }

    static async postInventoryItem(data) {
        return CRUDapi('POST', 'inventory', null, data)
    }

    static async postInventoryList(dataObjects) {
        return CRUDapi('POST', 'inventory/list', null, dataObjects)
    }

    static async putInventoryItem(id, data) {
        return CRUDapi('PUT', 'inventory', id, data)
    }

    static async deleteInventoryItem(id) {
        return CRUDapi('DELETE', 'inventory', id)
    }

}

export { InventoryRemoteDataSource }